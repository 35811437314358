import { Spin } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getQuizTestsList,
  getTestsTakenByUser,
} from "../../../quiz_board/actions";
import AssessmentCard from "./AssessmentCard";
import AssessmentCardVerified from "./AssessmentCardVerified";

const AssessmentSkillTest = () => {
  var testUser = JSON.parse(localStorage.getItem("userDetails"));
  const currentUserId = testUser?._id;
  const dispatch = useDispatch();
  const {
    quizTestsList,
    quizTestReports,
    quizTestsListLoading,
    quizTestReportsLoading,
  } = useSelector((state) => state.quizTest);

  useEffect(() => {
    quizAssessmentData();
  }, [currentUserId]);

  const quizAssessmentData = async () => {
    await Promise.all([
      dispatch(getQuizTestsList()),
      dispatch(getTestsTakenByUser(testUser._id)),
    ]);
  };

  return (
    <>
      {/* <div className="row mx-0 pt-5">*/}
      <div className='col-12 px-0 pt-3'>
        <div className='row mx-0 Assessment-cards-section'>
          <div className='col-12'>
            <div className='row mx-0 px-lg-5 py-4'>
              <Link
                to='/quiz'
                className='text-decoration-none text-primary d-flex pb-2 align-items-center'
              >
                <i className='bi bi-arrow-left me-2'></i>
                <span className='fw-semibold'>Back</span>
              </Link>
              <h4 className='ps-1 pb-4 fw-bolder'>Assessment</h4>
              <div className='row  rounded-1 mx-0  py-3 px-0 assessment-card-A'>
                {quizTestsListLoading && quizTestReportsLoading ? (
                  <div className='text-center w-100 py-5'>
                    <Spin />
                  </div>
                ) : (
                  <>
                    <div className='col-12 '>
                      <AssessmentCardVerified
                        quizTestsList={quizTestsList}
                        quizTestReports={quizTestReports}
                      />
                    </div>
                    <div className='col-12 pt-4'>
                      <AssessmentCard
                        quizTestsList={quizTestsList}
                        quizTestReports={quizTestReports}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*</div> */}
    </>
  );
};
export default AssessmentSkillTest;
