import QuizTestCard from "../../../ui/QuizTestCard.jsx";
import { useEffect, useState } from "react";
import {
  getQuizTestsList,
  getTestsTakenByUser,
} from "../../../../actions/operations.js";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Loader from "../../../../../../shared_components/Loader.jsx";

const QuizTestsList = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { quizTestsList, quizTestsListLoading, quizTestReports } = useSelector(
    (state) => state.quizTest,
  );
  const testUser = JSON.parse(localStorage.getItem("userDetails"));
  const [dropdown, setDropdown] = useState(true);
  const [previousDropdown, setPreviousDropdown] = useState(true);

  useEffect(() => {
    dispatch(getQuizTestsList());
    dispatch(getTestsTakenByUser(testUser._id))
      .then((result) => {
        console.log(result);
      })
      .catch((err) => {
        console.log("");
      });
  }, [id]);

  // Filter available and previously taken tests
  const availableTests = quizTestsList?.filter(
    (item) =>
      item.quizCategory.toLowerCase() === "quiztest" &&
      !quizTestReports?.some((report) => report.quizTest?._id === item._id),
  );

  const previousTests = quizTestsList?.filter(
    (item) =>
      item.quizCategory.toLowerCase() === "quiztest" &&
      quizTestReports?.some((report) => report.quizTest?._id === item._id),
  );

  return (
    <div className='quiz-list-body'>
      {quizTestsListLoading ? (
        <Loader />
      ) : (
        <>
          <div className='container'>
            <div className='pt-3 mb-4'>
              <Link
                to='/quiz'
                className='back-link d-inline-flex align-items-center'
              >
                <i className='bi bi-arrow-left me-2'></i>
                <span className='fw-semibold'>Back to Tests</span>
              </Link>
            </div>

            <div className='section-container'>
              <div
                onClick={() => {
                  setDropdown(!dropdown);
                }}
                className='d-flex align-items-center section-header'
                data-bs-toggle='collapse'
                data-bs-target='#availableTests'
              >
                {dropdown ? (
                  <i className='bi bi-chevron-compact-up'></i>
                ) : (
                  <i className='bi bi-chevron-compact-down'></i>
                )}
                <h5 className='mx-3'>Available Tests</h5>
              </div>

              <div className='collapse show' id='availableTests'>
                <div className='card-container'>
                  {availableTests?.length > 0 ? (
                    availableTests.map((item, index) => (
                      <div
                        className='quiz-card-wrapper'
                        key={item._id || index}
                      >
                        <QuizTestCard item={item} index={index} />
                      </div>
                    ))
                  ) : (
                    <p className='no-tests-message'>
                      No available tests at the moment.
                    </p>
                  )}
                </div>
              </div>
            </div>

            <div className='section-container'>
              <div
                onClick={() => {
                  setPreviousDropdown(!previousDropdown);
                }}
                className='d-flex align-items-center section-header '
                data-bs-toggle='collapse'
                data-bs-target='#previoustest'
              >
                {previousDropdown ? (
                  <i className='bi bi-chevron-compact-down'></i>
                ) : (
                  <i className='bi bi-chevron-compact-up'></i>
                )}{" "}
                <h5 className='mx-3'>Previously Taken Tests</h5>
              </div>

              <div className='collapse' id='previoustest'>
                <div className='card-container'>
                  {previousTests?.length > 0 ? (
                    previousTests.map((item, index) => {
                      const report = quizTestReports.find(
                        (report) => report.quizTest === item._id,
                      );
                      return (
                        <div
                          className='quiz-card-wrapper'
                          key={item._id || index}
                        >
                          <QuizTestCard
                            item={item}
                            index={index}
                            disable={true}
                            certificateUrl={report?.certificateUrl}
                            prev={true}
                            ispassed={report?.isPassed}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <p className='no-tests-message'>
                      You have not taken any tests yet.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default QuizTestsList;
