import { useState, useEffect } from "react";

const AssessmentTimer = ({ timeInMinutes, handleSubmit }) => {
  const getStoredTime = () => {
    const storedTime = localStorage.getItem("assessment-remaining-time");
    if (storedTime) {
      return parseInt(storedTime, 10);
    }
    return timeInMinutes * 60;
  };

  const [timeLeft, setTimeLeft] = useState(getStoredTime);

  useEffect(() => {
    if (timeLeft <= 0) {
      handleSubmit();
      localStorage.removeItem("assessment-remaining-time");
      return;
    }

    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(interval);
          localStorage.removeItem("assessment-remaining-time");
          handleSubmit();
          return 0;
        }
        const newTime = prevTime - 1;
        localStorage.setItem("assessment-remaining-time", newTime);
        return newTime;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [timeLeft]);

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = seconds % 60;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
      2,
      "0",
    )}:${String(secs).padStart(2, "0")}`;
  };

  return (
    <div className='row mx-0'>
      <div className='col-12 px-0'>
        <div className='d-flex gap-2 align-items-center justify-content-center text-center'>
          <div className='assessment-timer'>
            <p className='px-3 py-1 mb-0 rounded-2 fs-5 '>Time Remaining:</p>
          </div>
          <div className='assessment-timer'>
            <p className='px-3 py-1 mb-0 rounded-2 fs-5'>
              {formatTime(timeLeft)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentTimer;
