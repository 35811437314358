import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getTestsTakenByUser } from "../../../quiz_board/actions";

const AssessmentCardVerified = ({ quizTestsList, quizTestReports }) => {
  const [availableAssessments, setAvailableAssessments] = useState([]);

  useEffect(() => {
    if (quizTestsList && quizTestReports) {
      const reportedQuizIds = new Set(
        quizTestReports.map((report) => report.quizTest?._id),
      );

      const filteredTests = quizTestsList.filter(
        (quiz) =>
          quiz.quizCategory.toLowerCase() === "assessment" &&
          !reportedQuizIds.has(quiz._id),
      );

      setAvailableAssessments(filteredTests);
    }
  }, [quizTestsList, quizTestReports]);

  return (
    <div className='row mx-0'>
      <div className='col-12 px-0'>
        <div className='row mx-0'>
          <h6 className='ms-lg-2 fw-bolder fs-5'>Available Tests</h6>

          <div className='col-lg-12 col-12 d-lg-flex flex-lg-wrap'>
            {availableAssessments.length > 0 ? (
              availableAssessments.map((quiz, index) => {
                const hasQuestions = quiz.quizQuestions?.length > 0;
                return (
                  <div
                    key={quiz._id || index}
                    className='Assessment-card-manage'
                  >
                    <div className='card rounded m-lg-2 mb-2'>
                      <div className='card-body'>
                        <div className='d-flex justify-content-between align-items-center'>
                          <div className='d-flex gap-3 flex-row'>
                            <p className='py-0 fw-bold text-capitalize'>
                              {quiz.quizTitle}
                            </p>
                          </div>
                          <div>
                            <Link
                              to={
                                hasQuestions
                                  ? `/assessments/${quiz._id}`
                                  : "/assessments"
                              }
                              onClick={(e) => {
                                if (!hasQuestions) e.preventDefault();
                              }}
                            >
                              <button
                                className='btn btn-outline-primary fs-6 fw-bold'
                                disabled={!hasQuestions}
                              >
                                Get Verified
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className='col-12 text-center mt-3'>
                <p>No Assessments available !!</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentCardVerified;
