import AssessmentContinueButton from "../AssessmentContinueButton";
import AssessmentHeader from "./AssessmentHeader"; // Moved outside the main container
import AssessmentOptions from "./AssessmentOptions";
import AssessmentQuestions from "../AssessmentSkillTest/AssessmentQuestions";
import AssessmentTimer from "./AssessmentTimer";
import "./../../../../assets/scss/Assesment/Assessment.scss";
import AssessmentButton from "./AssessmentContinueButton";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { submitQuizTest } from "../../../quiz_board/actions";
import { stopTimer } from "../../actions/actionCreators";
import Loader from "../../../../shared_components/Loader";
import { fetchQuizTestDetails } from "../../actions/operations";

const Assessment = () => {
  const { currentQuizTest, quizTestDataLoading } =
    useSelector(({ quizTest }) => quizTest) || {};
  const { time } = useSelector((state) => state.timer);
  const dispatch = useDispatch();
  const { id } = useParams();

  const quizTestsQuestions = currentQuizTest?.quizQuestions;

  const [currentPage, setCurrentPage] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isloading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchQuizTestDetails(id)).then(() => {
      setIsLoading(false);
    });

    const savedAnswers = localStorage.getItem(`quiz-${id}-answers`);
    setAnswers(savedAnswers ? JSON.parse(savedAnswers) : []);

    const handleBeforeUnload = (e) => {
      e.preventDefault();
      return false;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [dispatch]);

  useEffect(() => {
    if (time.hours === 0 && time.minutes === 0 && time.seconds === 0) {
      localStorage.removeItem("testTimer");
      localStorage.removeItem("testEndTime");
      dispatch(stopTimer());
      handleSubmit();
      navigate(`/assessment/test/${id}/report`);
    }
  }, [time, dispatch, id, navigate]);

  const handlePageChange = (action) => {
    if (Array.isArray(quizTestsQuestions) && action === "Next") {
      setCurrentPage((prev) =>
        Math.min(prev + 1, quizTestsQuestions.length - 1),
      );
    }
  };

  const handleClick = () => {
    const totalQuestions = quizTestsQuestions.length;
    const selectedCount = answers.length;
    setProgress(Math.round((selectedCount / totalQuestions) * 100));
    handlePageChange("Next");
  };

  const handleOptionChange = (questionId, selectedAnswer) => {
    const updatedAnswers = [...answers];
    const index = updatedAnswers.findIndex(
      (answer) => answer.questionId === questionId,
    );

    if (index > -1) {
      updatedAnswers[index].selectedAnswer = selectedAnswer;
    } else {
      updatedAnswers.push({ questionId, selectedAnswer });
    }

    localStorage.setItem(`quiz-${id}-answers`, JSON.stringify(updatedAnswers));
    setAnswers(updatedAnswers);
  };

  const handleSubmit = async () => {
    try {
      const response = { quizTestId: id, answers };
      dispatch(submitQuizTest(response)).then((res) => {
        if (res) {
          const access_token = localStorage.getItem("access_token");
          const userDetails = localStorage.getItem("userDetails");
          localStorage.clear();
          localStorage.setItem("access_token", access_token);
          localStorage.setItem("userDetails", userDetails);
          navigate(`/assessment/test/${id}/report`);
        }
      });
    } catch (error) {
      console.error("Error submitting quizTest:", error);
      alert("There was an error submitting your quiz.");
    }
  };

  return (
    <>
      <AssessmentHeader progress={progress} />

      <div className=' assessment-container rounded '>
        {isloading ? (
          <Loader />
        ) : (
          <div className='border rounded-lg shadow-sm p-md-4 bg-light'>
            <div className='d-flex flex-column flex-md-row justify-content-between align-items-center py-2 px-4 border-bottom'>
              <h2 className='primary-text fw-bold py-2 py-md-0 fs-1 mb-2 mb-md-0'>
                {currentQuizTest &&
                  (currentQuizTest?.quizTechnology).charAt(0).toUpperCase() +
                    currentQuizTest.quizTechnology.slice(1) +
                    " Assessment"}
              </h2>
              {quizTestsQuestions && (
                <AssessmentTimer
                  timeInMinutes={currentQuizTest?.duration || 1}
                  handleSubmit={handleSubmit}
                />
              )}
            </div>

            <div className='row g-4 mt-3 mx-0'>
              <div className='col-lg-6 p-3 rounded shadow-sm bg-white '>
                {quizTestsQuestions && (
                  <AssessmentQuestions
                    answers={answers}
                    currentPage={currentPage}
                    quizTestsQuestions={quizTestsQuestions}
                    handleOptionChange={handleOptionChange}
                  />
                )}
              </div>

              <div className='col-lg-6 p-3 rounded shadow-sm bg-white '>
                <div className='assessment-options'>
                  {quizTestsQuestions && (
                    <AssessmentOptions
                      answers={answers}
                      currentPage={currentPage}
                      quizTestsQuestions={quizTestsQuestions}
                      handleOptionChange={handleOptionChange}
                    />
                  )}
                </div>
                <div className='mt-3'>
                  <AssessmentButton
                    currentPage={currentPage}
                    quizTestsQuestions={quizTestsQuestions}
                    handleClick={handleClick}
                    handleSubmit={handleSubmit}
                  />
                </div>
              </div>
            </div>

            <div className='mt-4 p-3 bg-light border rounded'>
              <h5 className='text-dark'>Instructions:</h5>
              <ul className='text-muted'>
                <li>Each question has only one correct answer.</li>
                <li>
                  You can revisit and change your answer before submission.
                </li>
                <li>Use the "Next" button to move to the next question.</li>
                <li>Make sure to submit your test before time runs out.</li>
              </ul>
            </div>

            <div className='text-end mt-4 border-top pt-3'>
              <p className='m-0 text-secondary'>
                Report an issue{" "}
                <a href='#' className='text-primary'>
                  here
                </a>
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Assessment;
