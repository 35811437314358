import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getQuizTestDetails } from "../../../quiz_board/actions";
import Loader from "../../../../shared_components/Loader";
import parse from "html-react-parser";

const WelcomeTestInstruction = () => {
  const [hasCompleted,setHasCompleted]=useState(false);
  const { currentQuizTest, quizTestDataLoading } =
    useSelector((state) => state.quizTest) || {};
  const dispatch = useDispatch();
  const { id } = useParams();
  const testUser = JSON.parse(localStorage.getItem("userDetails"));

  useEffect(() => {
    dispatch(getQuizTestDetails({ quizTest: id })).then((res)=>{
      setHasCompleted(res?.data?.hasCompleted);
    })
  }, []);

  const { quizTitle, quizQuestions, duration, instructions , cmpName } =
    currentQuizTest || {};

  return (
    <>
      {quizTestDataLoading ? (
        <Loader />
      ) : hasCompleted ? (
        <p className="d-flex justify-content-center my-5">Test is already completed by you</p>
      ) : (
        <div className="row mx-0 welcome-instructions">
          <div className="col-12 px-0 pt-2 welcome ">
            <div className="row mx-0 d-flex flex-wrap welcome-section">
              <div className="col-lg-6 py-3 welcome-section-A">
                <div className="pt-5 px-3">
                  <div className="pt-lg-5 d-flex flex-column gap-3 justify-content-center ps-lg-3 ps-1">
                    <h5>
                      Hello , {testUser?.data?.firstName} {testUser?.data?.lastName}
                    </h5>
                    <h3>Welcome to {cmpName? cmpName : "DataCode"} </h3>
                    <h4>{quizTitle}</h4>
                    <div className="py-1">
                      <p className="my-0 text-primary fw-bold">Test Duration</p>
                      <h5>{duration || 1} min</h5>
                    </div>
                    <div className="pb-4">
                      <p className="my-0 text-primary fw-bold">
                        No of Questions
                      </p>
                      <h5>{quizQuestions?.length}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 py-3 welcome-section-B">
                <div className="pt-5 px-2">
                  <div className="d-flex flex-column justify-content-center p-3">
                    <h3 className="text-primary fw-bold py-3">Instructions</h3>
                    {instructions ? (
                      <div>{parse(instructions)}</div>
                    ) : (
                      <div>
                        <ol className="d-flex flex-column ps-3 gap-2">
                          <li>
                            This is a timed test. Please make sure you are not
                            interrupted during the test, as the timer cannot be
                            paused once started.
                          </li>
                          <li>
                            Please ensure you have a stable internet connection.
                          </li>
                        </ol>
                      </div>
                    )}
                    <div className="pt-lg-5">
                      <Link to={`/assessment/test/${id}`}>
                        <button
                          className="btn btn-primary rounded-5 btn-lg py-1 px-5 fw-bold"
                        >
                          Continue
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default WelcomeTestInstruction;
