import {
  GET_QUIZ_TEST_DETAILS,
  GET_QUIZ_QUESTIONS,
  GET_QUIZ_TESTS_LIST,
  GET_TEST_QUESTIONS,
  GET_QUIZ_QUESTIONS_DETAILS,
  GET_QUIZ_OVERVIEW,
  GET_QUIZ_TEST_TAKEN,
  GET_CANDIDATE_DETAILS,
  GET_TEST_USER,
  GET_LOGIN_USER_DETAILS,
  GET_CANDIDATE_REPORT,
  GET_TEST_TAKEN_BY_USER,
} from "../constants";

//actions for test
export const setQuizTestDetails = (data) => ({
  type: GET_QUIZ_TEST_DETAILS,
  payload: data,
});

export const setQuizTestsList = (data) => ({
  type: GET_QUIZ_TESTS_LIST,
  payload: data,
});

//actions for quiztestquestions
export const setQuizQuestions = (data) => ({
  type: GET_QUIZ_QUESTIONS,
  payload: data,
});

export const setTestQuizQuestions = (data) => ({
  type: GET_TEST_QUESTIONS,
  payload: data,
});

export const setQuestionDetail = (data) => ({
  type: GET_QUIZ_QUESTIONS_DETAILS,
  payload: data,
});

//quiztest overview action
export const setQuizOverviewDetails = (data) => ({
  type: GET_QUIZ_OVERVIEW,
  payload: data,
});

export const setQuizOverviewTestTaken = (data) => ({
  type: GET_QUIZ_TEST_TAKEN,
  payload: data,
});

//all reports of the user
export const setReportsOfUser = (data) => ({
  type: GET_TEST_TAKEN_BY_USER,
  payload: data,
});

//reportReview action
export const setReportReviewDetails = (data) => ({
  type: GET_CANDIDATE_DETAILS,
  payload: data,
});

//report test
export const setReportDetail = (data) => ({
  type: GET_CANDIDATE_REPORT,
  payload: data,
});

// token
export const setTestUser = (decoded) => ({
  type: GET_TEST_USER,
  payload: decoded,
});

export const setLoginDetail = (data) => ({
  type: GET_LOGIN_USER_DETAILS,
  payload: data,
});
