import { Modal } from "antd";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect, useRef, useState } from "react";
import { FaDownload } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import datacodeImg from "../../../../../src/assets/images/datacode_certificatre_logo.png";
import Loader from "../../../../shared_components/Loader";
import {
  getCandidateReviewReport,
  getTestTakenByUser,
} from "../../../quiz_board/actions";

const AssessmentReport = () => {
  const { id } = useParams();
  const details = JSON.parse(localStorage.getItem("userDetails"));
  const certificateRef = useRef(null);
  const [certificateModal, setCertificateModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showCertificate, setShowCertificate] = useState(false);
  const [testTakenLoader, setTestTakenLoader] = useState(true);
  const [loader, setLoader] = useState(false);
  const [testTaken, setTestTaken] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchReports();
  }, []);

  const fetchReports = async () => {
    await dispatch(getTestTakenByUser({ userId: details?._id, quizTest: id }))
      .then((res) => {
        if (res?.success) {
          if (res.data?.reports?.length) {
            setTestTaken(res.data);
            setTestTakenLoader(false);
          } else {
            setTestTaken({});
            setTestTakenLoader(false);
          }
        } else {
          setTestTaken({});
          setTestTakenLoader(false);
        }
      })
      .catch((error) => {
        setTestTaken({});
        setTestTakenLoader(false);
      });
  };

  const report = testTaken?.reports?.[0] || null;
  console.log("Current Report:", report); // Log current report
  // console.log(report);

  const downloadCertificate = async () => {
    const certificateElement = certificateRef.current;

    if (certificateElement) {
      const canvas = await html2canvas(certificateElement, {
        scale: 2,
        useCORS: true,
      });

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("landscape", "mm", "a4");

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const imgWidth =
        canvas.width > canvas.height
          ? pdfWidth
          : (pdfWidth / canvas.height) * canvas.width;

      const imgHeight =
        canvas.width > canvas.height
          ? (pdfHeight / canvas.width) * canvas.height
          : pdfHeight;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

      pdf.save("certificate.pdf");
    }
  };

  const openCertificateModal = (item) => {
    setLoader(true);
    setCertificateModal(true);
    setShowCertificate(false);
    setProgress(0);
    dispatch(getCandidateReviewReport(item)).then((res) => {
      setLoader(false);
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(interval);
            setShowCertificate(true);
            return 100;
          }
          return prev + 10;
        });
      }, 100);
    });
  };

  // Determine success or failure based on total score vs cutoff
  const isSuccess = report?.totalScore >= report?.cutoff;
  const iconColor = isSuccess ? "#28a745" : "#dc3545";

  return (
    <>
      {testTakenLoader ? (
        <Loader />
      ) : report ? (
        <div className='row mx-0 mt-5 pt-5 px-3 d-flex justify-content-center assessment-report'>
          <div className='col-lg-6 col-sm-12 px-0 report-container'>
            <i
              className={`bi ${
                isSuccess ? "bi-check-circle-fill" : "bi-emoji-frown"
              } report-icon`}
              style={{ color: iconColor }}
            ></i>
            <h4 className='report-heading'>
              Thank you for Participating in the Assessment Test!
            </h4>
            <h5>
              {isSuccess ? (
                <>
                  Yeah !! You{" "}
                  <span className='score-value' style={{ color: iconColor }}>
                    Passed
                  </span>{" "}
                  this test ⭐
                </>
              ) : (
                <span>Oops !! Better Luck next time.</span>
              )}
            </h5>

            <h5 className='report-score py-2'>
              <p className='score-tag' style={{ fontSize: "34px" }}>
                Your Score :{" "}
              </p>
              <p
                className='score-value'
                style={{ color: iconColor, fontSize: "75px" }}
              >
                {report?.totalScore}
              </p>
            </h5>
            {report.isPassed && (
              <p className='pt-3'>
                <span
                  onClick={() => {
                    openCertificateModal(report._id);
                  }}
                  className='btn btn-outline-success btn-sm fw-bold px-4 py-2 rounded-pill shadow-sm d-inline-flex align-items-center'
                >
                  View Certificate
                  <i className='fas fa-external-link-alt ms-2'></i>
                </span>
              </p>
            )}

            <p className='footer-note'>
              We appreciate your effort. Keep improving!
            </p>
            <Link to='/assessments' replace>
              <p>Back to Assessments</p>
            </Link>
          </div>
        </div>
      ) : (
        <h2>Error loading report found</h2>
      )}
      {certificateModal && (
        <Modal
          width={850}
          open={certificateModal}
          title='Certificate'
          onCancel={() => setCertificateModal(false)}
          footer={[
            <button
              key='download'
              className='btn btn-success mx-2 vertical-align-middle'
              onClick={downloadCertificate}
              disabled={!showCertificate}
            >
              <span className='d-flex align-items-center'>
                <FaDownload className='me-2' />
                <span>Download Certificate</span>
              </span>
            </button>,
            <button
              key='close'
              className='btn btn-primary'
              onClick={() => setCertificateModal(false)}
            >
              Close
            </button>,
          ]}
        >
          {!showCertificate ? (
            <div className='text-center'>
              <p>Loading certificate...</p>
              <div
                style={{
                  margin: "20px auto",
                  width: "80%",
                  height: "20px",
                  borderRadius: "10px",
                  background: "rgba(200, 200, 200, 0.4)",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: `${progress}%`,
                    background: "linear-gradient(90deg, #6a11cb, #2575fc)",
                    borderRadius: "10px",
                    transition: "width 0.2s ease",
                  }}
                />
              </div>
              <span>{progress}%</span>
            </div>
          ) : (
            <div ref={certificateRef} className='row mx-0 border'>
              <div className='col-12 px-0'>
                <div className='row mx-0 w-100 h-100 datacode-certificate'>
                  <div className='col-12 col-md-7 px-3 px-md-5'>
                    <img
                      src={datacodeImg}
                      alt='Datacode logo'
                      className='my-4 my-md-5 mx-0 w-100 object-fit-cover'
                      height='100px'
                    />
                    <p className='mt-4 mt-md-5 mb-0 sub-title'>
                      This certificate is presented to:
                    </p>
                  </div>
                  <div className='col-12 col-md-9 px-3 px-md-5'>
                    <h1 className='pt-3 pt-md-0'>
                      {details?.firstName + " " + details?.lastName ||
                        "Student Name"}
                    </h1>
                    <p className='mb-4 mb-md-5 description'>
                      Assessment Completed, Success Achieved!
                      <br />
                      Congratulations on Your Well-Deserved Success!
                    </p>
                    <div className='my-4 my-md-5 pt-4 pt-md-5'>
                      <span className='oragnised'>ASSESSMENT ORGANIZED BY</span>
                      <br />
                      <span className='host'>DATACODE COMMUNITY</span>
                    </div>
                    <span className='text-secondary'>
                      Certificate Id : {report?._id}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default AssessmentReport;
