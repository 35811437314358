const AssessmentOptions = ({
  handleOptionChange,
  quizTestsQuestions,
  currentPage,
  answers,
}) => {
  const { _id, option1, option2, option3, option4 } =
    (quizTestsQuestions && quizTestsQuestions[currentPage]) || {};

  const selectedAnswer = answers.find(
    (answer) => answer.questionId === _id,
  )?.selectedAnswer;

  return (
    <div className='container'>
      <div className='mb-3'>
        <h5 className='d-none d-lg-block fw-bold'>Instructions</h5>
      </div>
      <div className='options'>
        {[option1, option2, option3, option4].map((option, optIndex) => {
          const isSelected = selectedAnswer === option;
          return (
            <div key={optIndex} className='mb-2'>
              <div
                className={`option-block p-3 d-flex align-items-center rounded border ${
                  isSelected ? "border-primary bg-light" : "border-secondary"
                }`}
                onClick={() => handleOptionChange(_id, option)}
                style={{ cursor: "pointer", transition: "0.3s" }}
              >
                <input
                  className='form-check-input mt-0 me-2'
                  type='radio'
                  name={`question-${_id}`}
                  value={option}
                  checked={isSelected}
                  onChange={() => handleOptionChange(_id, option)}
                  id={`question-${_id}-option-${optIndex}`}
                />
                <label
                  className={`form-check-label  flex-grow-1 ${
                    isSelected ? "text-primary fw-bold " : "text-dark"
                  }`}
                  style={{ textAlign: "justify" }}
                  htmlFor={`question-${_id}-option-${optIndex}`}
                >
                  {option}
                </label>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AssessmentOptions;
