import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getQuizTestDetails } from "../../../quiz_board/actions";
import { useEffect } from "react";

const AssessmentButton = ({
  currentPage,
  handleClick,
  handleSubmit,
  quizTestsQuestions = [],
}) => {
  // const QuizTestDetails = useSelector(({ quizTest }) => quizTest) || {};
  // const dispatch = useDispatch();
  const { id } = useParams();

  // useEffect(() => {
  //   dispatch(getQuizTestDetails({ quizTest: id }));
  // }, [id, dispatch]);
  return (
    <>
      <div className='row mx-0'>
        <div className='col-12 py-2'>
          {currentPage < quizTestsQuestions.length - 1 && (
            <div className=' d-flex justify-content-end'>
              <button
                className='btn btn-lg py-1 btnn-btnn datacode-primary-btn'
                onClick={() => {
                  handleClick();
                }}
              >
                <b>Next</b>
              </button>
            </div>
          )}

          {currentPage === quizTestsQuestions.length - 1 && (
            <div className='d-flex justify-content-end'>
              <Link to={`/assessment/test/${id}/report`}>
                <button
                  className='btn  py-1 btnn-btnn datacode-primary-btn'
                  onClick={() => {
                    handleClick();
                    handleSubmit();
                  }}
                >
                  <b>Finish</b>
                </button>
              </Link>{" "}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default AssessmentButton;
