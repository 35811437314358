import { Modal, Spin } from "antd";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect, useRef, useState } from "react";
import { FaDownload } from "react-icons/fa6";
import { PiCertificateBold } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import datacodeImg from "../../../../../src/assets/images/datacode_certificatre_logo.png";
import { getCandidateReviewReport } from "../../../quiz_board/actions";

const AssessmentCard = ({ quizTestsList, quizTestReports }) => {
  const [loader, setLoader] = useState(false);
  const [certificateModal, setCertificateModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showCertificate, setShowCertificate] = useState(false);
  const certificateRef = useRef(null);
  const dispatch = useDispatch();
  const { candidateReportReviewDetails } = useSelector(
    (state) => state.quizTest,
  );

  useEffect(() => {
    if (quizTestsList && quizTestReports) {
      const reportedQuizIds = new Set(
        quizTestReports.map((report) => report.quizTest?._id),
      );

      const filteredTests = quizTestReports.filter((report) => {
        const quiz = quizTestsList.find(
          (quizTest) => quizTest._id === report.quizTest?._id,
        );
        return (
          quiz &&
          quiz.quizCategory.toLowerCase() === "assessment" &&
          reportedQuizIds.has(report.quizTest?._id)
        );
      });

      setAvailableAssessments(filteredTests);
    }
  }, [quizTestsList, quizTestReports]);

  const downloadCertificate = async () => {
    const certificateElement = certificateRef.current;

    if (certificateElement) {
      const canvas = await html2canvas(certificateElement, {
        scale: 2,
        useCORS: true,
      });

      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("landscape", "mm", "a4");

      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      const imgWidth =
        canvas.width > canvas.height
          ? pdfWidth
          : (pdfWidth / canvas.height) * canvas.width;

      const imgHeight =
        canvas.width > canvas.height
          ? (pdfHeight / canvas.width) * canvas.height
          : pdfHeight;

      pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);

      pdf.save("certificate.pdf");
    }
  };

  const openCertificateModal = (item) => {
    setLoader(true);
    setCertificateModal(true);
    setShowCertificate(false);
    setProgress(0);
    dispatch(getCandidateReviewReport(item)).then(() => {
      setLoader(false);
      console.log(candidateReportReviewDetails, "details");
      const interval = setInterval(() => {
        setProgress((prev) => {
          if (prev >= 100) {
            clearInterval(interval);
            setShowCertificate(true);
            return 100;
          }
          return prev + 10;
        });
      }, 100);
    });
  };

  const [availableAssessments, setAvailableAssessments] = useState([]);

  return (
    <>
      <div className='row mx-0'>
        <div className='col-12 px-0'>
          <div className='row mx-0'>
            <h6 className='ms-lg-2 fw-bolder fs-5'>Previously Taken Tests</h6>
            <div className='col-lg-12 col-12 d-lg-flex flex-lg-wrap'>
              {availableAssessments.length > 0 ? (
                availableAssessments.map((item, index) => {
                  return (
                    <div key={index} className='Assessment-card-manage'>
                      <div className='card rounded m-lg-2 mb-2'>
                        <div className='card-body'>
                          <div className='d-flex justify-content-between align-items-center'>
                            <p className='py-0 fw-bold'>
                              {item?.quizTest?.quizTitle}
                            </p>
                            <div>
                              <button
                                className={`btn fs-6 ${
                                  item.isPassed
                                    ? "btn-outline-success"
                                    : "btn-outline-danger"
                                }`}
                                disabled
                              >
                                {item.isPassed ? "Passed" : "Failed"}
                              </button>
                              {item.isPassed && (
                                <span
                                  className='btn btn-outline-warning text-dark ms-2'
                                  onClick={() => {
                                    openCertificateModal(item._id);
                                  }}
                                >
                                  <PiCertificateBold fontSize={25} />
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className='col-12 text-center mt-3'>
                  <p>No Previous Assessments available !!</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {certificateModal && (
        <Modal
          width={850}
          open={certificateModal}
          title='Certificate'
          onCancel={() => setCertificateModal(false)}
          footer={[
            <button
              key='download'
              className='btn btn-success mx-2 vertical-align-middle'
              onClick={downloadCertificate}
              disabled={!showCertificate}
            >
              <span className='d-flex align-items-center'>
                <FaDownload className='me-2' />
                <span>Download Certificate</span>
              </span>
            </button>,
            <button
              key='close'
              className='btn btn-primary'
              onClick={() => setCertificateModal(false)}
            >
              Close
            </button>,
          ]}
        >
          {!showCertificate ? (
            <div className='text-center'>
              <p>Loading certificate...</p>
              <div
                style={{
                  margin: "20px auto",
                  width: "80%",
                  height: "20px",
                  borderRadius: "10px",
                  background: "rgba(200, 200, 200, 0.4)",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    height: "100%",
                    width: `${progress}%`,
                    background: "linear-gradient(90deg, #6a11cb, #2575fc)",
                    borderRadius: "10px",
                    transition: "width 0.2s ease",
                  }}
                />
              </div>
              <span>{progress}%</span>
            </div>
          ) : (
            <div ref={certificateRef} className='row mx-0 border'>
              <div className='col-12 px-0'>
                <div className='row mx-0 w-100 h-100 datacode-certificate'>
                  <div className='col-12 col-md-7 px-3 px-md-5'>
                    <img
                      src={datacodeImg}
                      alt='Datacode logo'
                      className='my-4 my-md-5 mx-0 w-100 object-fit-cover'
                      height='100px'
                    />
                    <p className='mt-4 mt-md-5 mb-0 sub-title'>
                      This certificate is presented to:
                    </p>
                  </div>
                  <div className='col-12 col-md-9 px-3 px-md-5'>
                    <h1 className='pt-3 pt-md-0'>
                      {candidateReportReviewDetails?.user.firstName +
                        " " +
                        candidateReportReviewDetails?.user.lastName ||
                        "Student Name"}
                    </h1>
                    <p className='mb-4 mb-md-5 description'>
                      Assessment Completed, Success Achieved!
                      <br />
                      Congratulations on Your Well-Deserved Success!
                    </p>
                    <div className='my-4 my-md-5 pt-4 pt-md-5'>
                      <span className='oragnised'>ASSESSMENT ORGANIZED BY</span>
                      <br />
                      <span className='host'>DATACODE COMMUNITY</span>
                    </div>
                    <span className='text-secondary'>
                      Certificate Id : {candidateReportReviewDetails?._id}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
      )}
    </>
  );
};

export default AssessmentCard;
